import React from 'react'
import styled from 'styled-components'
import { customMedia } from '../../common/customMedia'

export interface Props {
  formik: any
}

export const Form = (props: Props) => {
  const { formik } = props

  return (
    <>
      <InputWrapper>
        <Label>
          <Asterisk>※</Asterisk>個人情報の取り扱いについて
        </Label>
        <RadioContainer>
          <RadioWrapper>
            <Radio
              id="radio1"
              type="radio"
              name="agree"
              onChange={formik.handleChange}
              value="agree"
            />
            <label htmlFor="radio1">同意する</label>
          </RadioWrapper>

          <RadioWrapper>
            <Radio
              id="radio2"
              type="radio"
              name="agree"
              onChange={formik.handleChange}
              value="disagree"
            />
            <label htmlFor="radio2">同意しない</label>
          </RadioWrapper>

          <LinkText href="https://www.remohab.com/policy" target="_blank">
            ▶︎個人情報の取り扱いについて
          </LinkText>
        </RadioContainer>
      </InputWrapper>

      <InputWrapper>
        <Label>
          <Asterisk>※</Asterisk>氏名（漢字）
        </Label>
        <NameInputContainer>
          <NameInputWrapper>
            <NameLabel>姓</NameLabel>
            <NameInput
              name="lastname"
              onChange={formik.handleChange}
              value={formik.values.lastname}
            />
          </NameInputWrapper>
          <NameInputWrapper>
            <NameLabel>名</NameLabel>
            <NameInput
              name="firstname"
              onChange={formik.handleChange}
              value={formik.values.firstname}
            />
          </NameInputWrapper>
        </NameInputContainer>
      </InputWrapper>

      <InputWrapper>
        <Label>
          <Asterisk>※</Asterisk>氏名（フリガナ）
        </Label>
        <NameInputContainer>
          <NameInputWrapper>
            <NameLabel>姓</NameLabel>
            <NameInput
              name="lastnameKana"
              onChange={formik.handleChange}
              value={formik.values.lastnameKana}
            />
          </NameInputWrapper>
          <NameInputWrapper>
            <NameLabel>名</NameLabel>
            <NameInput
              name="firstnameKana"
              onChange={formik.handleChange}
              value={formik.values.firstnameKana}
            />
          </NameInputWrapper>
        </NameInputContainer>
      </InputWrapper>

      <InputWrapper>
        <Label>
          <Asterisk>※</Asterisk>メールアドレス（半角）
        </Label>
        <Input
          name="email"
          onChange={formik.handleChange}
          value={formik.values.email}
        />
      </InputWrapper>

      <InputWrapper>
        <Label>
          <Asterisk>※</Asterisk>ご連絡先電話番号（半角）
        </Label>
        <Input
          name="tel"
          onChange={formik.handleChange}
          value={formik.values.tel}
        />
      </InputWrapper>

      <InputWrapper>
        <Label>
          <Asterisk>※</Asterisk>ご経歴サマリ
        </Label>
        <Textarea
          name="content"
          onChange={formik.handleChange}
          value={formik.values.content}
        />
      </InputWrapper>

      <Error>
        {formik.errors.agree ? <div>{formik.errors.agree}</div> : null}
        {formik.errors.lastname ? <div>{formik.errors.lastname}</div> : null}
        {formik.errors.firstname ? <div>{formik.errors.firstname}</div> : null}
        {formik.errors.lastnameKana ? (
          <div>{formik.errors.lastnameKana}</div>
        ) : null}
        {formik.errors.firstnameKana ? (
          <div>{formik.errors.firstnameKana}</div>
        ) : null}
        {formik.errors.email ? <div>{formik.errors.email}</div> : null}
        {formik.errors.tel ? <div>{formik.errors.tel}</div> : null}
        {formik.errors.content ? <div>{formik.errors.content}</div> : null}
      </Error>
    </>
  )
}

const Asterisk = styled.span`
  color: #ff0003;
`

const Instruction = styled.div`
  ${customMedia.lessThan('medium')`
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 2rem;
    margin-bottom: 73px;
  `}

  color: #60656b;
`

const Label = styled.label`
  ${customMedia.lessThan('medium')`
    display: block;
    font-size: 1rem;
    margin-bottom: 7px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 2rem;
    min-width: 350px;
  `}

  color: #60656b;
`

const InputWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin: 24px 0;
  `}
  ${customMedia.greaterThan('medium')`
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: center;
    align-items: center;
    margin: 36px 0;
  `}

  :last-child {
    -webkit-align-items: start;
    align-items: start;
  }
`

const NameInputContainer = styled.div`
  ${customMedia.lessThan('medium')`
  `}
  ${customMedia.greaterThan('medium')`
  `}

  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  width: 100%;
`

const NameInputWrapper = styled.div`
  ${customMedia.lessThan('medium')`
  `}
  ${customMedia.greaterThan('medium')`
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: center;
    align-items: center;

    :nth-child(1) {
      label {
        left: -48px;
      }
    }

    :nth-child(2) {
      label {
        left: 0px;
      }
      input {
        position: absolute;
        right: 0;
      }
    }
    width: 50%;
  `}

  position: relative;
`

const NameLabel = styled.label`
  ${customMedia.lessThan('medium')`
    margin-right: 6.5px;
  `}
  ${customMedia.greaterThan('medium')`
    position: absolute;
    font-size: 2rem;
  `}

  color: #60656b;
`

const RadioContainer = styled.div`
  ${customMedia.lessThan('medium')`
    margin-top: 18px;
  `}
  ${customMedia.greaterThan('medium')`
    -webkit-justify-content: space-between;
    justify-content: space-between;
  `}

  display: -webkit-flex;
  display: flex;
`

const RadioWrapper = styled.div`
  ${customMedia.lessThan('medium')`
  `}
  ${customMedia.greaterThan('medium')`
    margin-right: 30px;
  `}
`

const Radio = styled.input`
  ${customMedia.lessThan('medium')`
    + label {
      font-size: 1rem;
      margin-right: 20px;
      padding-left: 30px;
    }
    
    + label::before {
      height: 20px;
      left: 0;
      top: -4px;
      width: 20px;
    }

    :checked + label::after {
      height: 20px;
      left: 0;
      top: -4px;
      width: 20px;
    }
  `}
  ${customMedia.greaterThan('medium')`
    + label {
      font-size: 2rem;
      margin-right: 20px;
      padding-left: 50px;
    }
    
    + label::before {
      height: 40px;
      left: 0;
      top: -8px;
      width: 40px;
    }

    :checked + label::after {
      height: 40px;
      left: 0;
      top: -8px;
      width: 40px;
    }
  `}

  display: none;

  + label {
    color: #60656b;
    cursor: pointer;
    position: relative;
  }

  + label::before {
    content: '';
    background-color: #8d8d8d;
    border: 1px #000 solid;
    border-radius: 50%;
    display: block;
    position: absolute;
  }

  :checked + label::after {
    content: '';
    background-color: #26d2ad;
    border: 1px #000 solid;
    border-radius: 50%;
    display: block;
    position: absolute;
  }
`

const LinkText = styled.a`
  ${customMedia.lessThan('medium')`
    right: 0;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 2rem;
    right: 0;
  `}

  color: #00b0ff;
  cursor: pointer;
  position: absolute;
  text-decoration: underline;
`

const Input = styled.input`
  ${customMedia.lessThan('medium')`
    height: 22.5px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 1.8rem;
    height: 45px;
    padding: 4px;
  `}

  background-color: #fff;
  border: 1px #60656b solid;
  width: 100%;
`

const NameInput = styled.input`
  ${customMedia.lessThan('medium')`
    height: 22.5px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 1.8rem;
    height: 45px;
    padding: 4px;
    width: 280px;
  `}
  
  background-color: #fff;
  border: 1px #60656b solid;
`

const Textarea = styled.textarea`
  ${customMedia.lessThan('medium')`
    height: 175px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 1.8rem;
    height: 350px;
    padding: 4px;
  `}

  background-color: #fff;
  border: 1px #60656b solid;
  resize: none;
  width: 100%;
`

const Error = styled.div`
  ${customMedia.lessThan('medium')`
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 1.6rem;
  `}

  color: #ff0003;
`

const ButtonWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin-top: 44px;
  `}
  ${customMedia.greaterThan('medium')`
    margin-top: 117px;
  `}

  text-align: center;
`
