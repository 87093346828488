import React from 'react'
import styled from 'styled-components'

import { Layout } from '../components/Layout'
import { SEO } from '../components/common/SEO'

import { Recruit } from '../components/Recruit'

const IndexPage = () => (
  <Layout>
    <SEO
      title='遠隔医療・心臓リハビリの求人転職探しなら'
      description='リモハブは、世界一の在宅疾病管理プラットフォームとなることを目指して、共に働くスタッフを募集しています。すぐそこにある未来を、デバイスを通して、少しでも多くの人が「健幸」になることを願うサービス、Remohabであなたの力を活かしませんか？'
      keywords={['遠隔医療','心臓リハビリ','求人','転職','リクルート','リモハブ','remohab']}
    />
    <H1>遠隔医療　心臓リハビリ　求人　転職　リクルート | Remohab リモハブ</H1>
    <Recruit />
  </Layout>
)

const H1 = styled.h1`
  font-size: 1.8rem;
`

export default IndexPage
