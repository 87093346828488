import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { customMedia } from '../../common/customMedia'
import { MyButton } from '../../common/MyButton'

export const Complete = () => {
  return (
    <>
      <Text>お問い合わせを受け付けました。</Text>

      <ButtonWrapper>
        <Link to='/'>
          <MyButton type='submit' text='トップに戻る' />
        </Link>
      </ButtonWrapper>
    </>
  )
}

const Text = styled.div`
  ${customMedia.lessThan("large")`
    font-size: 1rem;
    margin: 10px;
  `}
  ${customMedia.greaterThan("large")`
    font-size: 2rem;
    margin: 20px;
  `}

  color: #60656b;
  text-align: center;
`


const ButtonWrapper = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}

  text-align: center;
`