import React from 'react'
import styled from 'styled-components'
import { customMedia } from '../../common/customMedia'
import { MyButton } from '../../common/MyButton'

export interface Props {
  formik: any
}

export const Confirm = (props: Props) => {
  const { formik } = props
  return (
    <>
      <Text>以下の内容でよろしいですか？</Text>

      <Text>
        氏名: {formik.values.lastname} {formik.values.firstname}
      </Text>
      <Text>
        氏名（フリガナ）: {formik.values.lastnameKana}{' '}
        {formik.values.firstnameKana}
      </Text>
      <Text>メールアドレス: {formik.values.email}</Text>
      <Text>ご連絡先電話番号: {formik.values.tel}</Text>
      <Text>ご経歴サマリ: {formik.values.content}</Text>
    </>
  )
}

const Text = styled.div`
  ${customMedia.lessThan('large')`
    font-size: 1rem;

    :first-child {
      margin-bottom: 10px;
    }
  `}
  ${customMedia.greaterThan('large')`
    font-size: 2rem;

    :first-child {
      margin-bottom: 20px;
    }
  `}

  color: #60656b;
`
