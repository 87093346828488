import React from 'react'
import styled from 'styled-components'
import { customMedia } from '../customMedia'

export interface Props {
  text: string
  type?: 'button' | 'submit' | 'reset'
}

export const MyButton = (props: Props) => {
  return (
    <>
      <Button type={props.type}>{props.text}</Button>
    </>
  )
}

const Button = styled.button`
${customMedia.lessThan("small")`
    border-radius: 5px;
    font-size: 1.1rem;
    height: 36px;
    margin: 0 auto;
    width: 80%;
  `}
  ${customMedia.between("small", "medium")`
    border-radius: 5px;
    font-size: 1.1rem;
    height: 36px;
    width: 240px;
  `}
  ${customMedia.greaterThan("medium")`
    border-radius: 10px;
    font-size: 2rem;
    padding-top: 5px;
    height: 60px;
    width: 400px;
  `}

  background-color: #26d2ad;
  border: none;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
  text-align: center;
  
  
  :focus {
    outline:0;
  }

  :hover {
    background-color: #a3efde;
    -webkit-transition: all .3s;
    transition: all .1s;
  }
`

