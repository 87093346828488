import React, { useState } from 'react'
import { Link } from 'gatsby'
import { useFormik } from 'formik'
import axios from 'axios'
import styled from 'styled-components'
import { Reveal } from 'react-genie'
import { customMedia } from '../common/customMedia'
import { Image } from '../common/Image'
import { MyButton } from '../common/MyButton'
import { useTranslation, Trans } from 'react-i18next'
import { Form } from './Form'
import { Confirm } from './Confirm'
import { Complete } from './Complete'

export const Recruit = () => {
  const [t, i18n] = useTranslation()

  const [confirm, setConfirm] = useState(false)
  const [complete, setComplete] = useState(false)

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      type: 'Recruit',
      agree: null,
      firstname: '',
      lastname: '',
      firstnameKana: '',
      lastnameKana: '',
      company: '',
      department: '',
      email: '',
      tel: '',
      content: ''
    },
    validate: values => {
      const errors: { [key: string]: string } = {}

      if (!values.agree) {
        errors.agree = '個人情報の取り扱いへの同意は必須です'
      } else if (values.agree === 'disagree') {
        errors.agree = '個人情報の取り扱いに同意してください'
      }
      if (!values.firstname) {
        errors.firstname = '氏名（漢字）姓 は必須です'
      }
      if (!values.lastname) {
        errors.lastname = '氏名（漢字）名 は必須です'
      }
      if (!values.firstnameKana) {
        errors.firstnameKana = '氏名（フリガナ）姓 は必須です'
      }
      if (!values.lastnameKana) {
        errors.lastnameKana = '氏名（フリガナ）名 は必須です'
      }
      if (!values.email) {
        errors.email = 'メールアドレスは必須です'
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = 'メールアドレス が無効な形式です'
      }
      if (!values.tel) {
        errors.tel = 'ご連絡先電話番号は必須です'
      }
      if (!values.content) {
        errors.content = 'お問い合わせ内容は必須です'
      }

      return errors
    },
    onSubmit: values => {
      if (confirm) {
        axios
          .post(
            'https://us-central1-remohab-prod.cloudfunctions.net/sendMail',
            {
              data: values
            }
          )
          .then(res => {
            console.log(res)
            setComplete(true)
          })
          .catch(e => {
            console.log(e)
          })
      } else {
        setConfirm(true)
      }
    }
  })

  return (
    <>
      <Container>
        <Wrapper>
          <RecruitIconWrapper>
            <Image path="recruit@2x.png" />
          </RecruitIconWrapper>
          {i18n.language === 'ja' ? (
            <>
              <Content>
                リモハブのVision・Missionに共感し、リモハブの目指す世界の実現のために共に共有してくれる仲間を募集しております。
              </Content>
              <SubContent>
                現在募集中の求人情報詳細については「リモハブ採用ページ」をご覧ください
              </SubContent>
              <a href="https://remohab-recruit.notion.site/ac9a0770f13f48e3b2bcf8332f443f32?pvs=4">
                <LinkButtonWrapper>
                  <MyButton text={`${t('goToCareersPage')} ＞`} />
                </LinkButtonWrapper>
              </a>
              <SubContent>
                経験の有無は不問、経験・能力による業務内容・勤務形態・雇用条件などご相談に応じます。
              </SubContent>
              <SubContent>
                ご興味のある方は、下記フォームよりご連絡ください。
              </SubContent>
            </>
          ) : (
            <>
              <Content>Join Us</Content>
              <Content>
                We are looking for new members who feel sympathy with our Vision
                / Mission and challenge together to realize the world we want to
                create.
              </Content>
              <Content>
                For details on our current job openings, please visit the
                'Remohab Careers Page'.
              </Content>
              <a href="https://remohab-recruit.notion.site/ac9a0770f13f48e3b2bcf8332f443f32?pvs=4">
                <LinkButtonWrapper>
                  <MyButton text={`${t('goToCareersPage')} ＞`} />
                </LinkButtonWrapper>
              </a>
              <Content>
                No worries about your job experience. According to your job
                experience and skills, we can think about your job descriptions,
                work styles and employment conditions.
              </Content>
              <Content>
                Take the first step to make the better world where people can
                live healthily all their life long!
              </Content>
            </>
          )}

          {complete ? (
            <Complete />
          ) : confirm ? (
            <form onSubmit={formik.handleSubmit}>
              <Confirm formik={formik} />
              <SubmitButtonWrapper>
                <MyButton type="submit" text="送信" />
              </SubmitButtonWrapper>
            </form>
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <Form formik={formik} />
              <SubmitButtonWrapper>
                <MyButton type="submit" text="入力内容を確認する ＞" />
              </SubmitButtonWrapper>
            </form>
          )}
        </Wrapper>
      </Container>
    </>
  )
}

const Container = styled.div`
  ${customMedia.lessThan('medium')`
    padding-top: 80px;
  `}
  ${customMedia.greaterThan('medium')`
    padding-top: 80px;
  `}

  background-color: #efefef;
`

const Wrapper = styled.div`
  ${customMedia.lessThan('large')`
    padding: 0 20px 20px;
  `}
  ${customMedia.greaterThan('large')`
    margin: 0 auto;
    padding: 70px 0;
    width: 1000px;
  `}

  position: relative;
`

const RecruitIconWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin: 0 auto 25px;
    width: 72px;
  `}
  ${customMedia.greaterThan('medium')`
    margin: 0 auto 60px;
    width: 144px;
  `}
`

const Content = styled.h1`
  ${customMedia.lessThan('medium')`
    font-size: 1.5rem;
    margin-bottom: 20px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 2.5rem;
    margin-bottom: 30px;
  `}

  color: #60656b;
  font-weight: bold;
`

const SubContent = styled.h2`
  ${customMedia.lessThan('medium')`
    font-size: 1.05rem;
    margin-bottom: 20px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 1.8rem;
    margin-bottom: 25px;
  `}

  color: #60656b;
`

const MailLink = styled.a`
  color: #00a0e9;
  cursor: pointer;
  text-decoration: underline;
`

const LinkButtonWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin: 20px 0;
  `}
  ${customMedia.greaterThan('medium')`
    margin: 20px 0;
  `}

  text-align: center;
`

const SubmitButtonWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin-top: 44px;
  `}
  ${customMedia.greaterThan('medium')`
    margin-top: 117px;
  `}

  text-align: center;
`
